export default {
  path: '/order',
  component: (): any => import('@/views/order/Order.vue'),
  meta: {
    auth: true,
    title: 'order',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
  },
  children: [
    {
      path: 'list',
      name: 'Order',
      component: (): any => import('@/views/order/pages/OrderList.vue'),
      meta: {
        auth: true,
        title: 'รายการคำสั่งซื้อ',
        icon: 'mdi-list-box-outline',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'create',
      name: 'OrderCreate',
      component: (): any => import('@/views/order/pages/OrderCreate.vue'),
      meta: {
        auth: true,
        title: 'เพิ่มคำสั่งซื้อ',
        icon: 'mdi-list-box-outline',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'tax-create/:id',
      name: 'OrderTaxCreate',
      component: (): any => import('@/views/order/pages/OrderTaxCreate.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดคำสั่งซื้อ',
        icon: 'mdi-list-box-outline',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'tax-print/:id',
      name: 'OrderTaxPrint',
      component: (): any => import('@/views/order/pages/OrderTaxPrint.vue'),
      meta: {
        auth: true,
        layout: 'blank',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'info/:id',
      name: 'OrderInfo',
      component: (): any => import('@/views/order/pages/OrderInfo.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดคำสั่งซื้อ',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'receipt/:id',
      name: 'OrderReceipt',
      component: (): any => import('@/views/order/pages/OrderReceipt.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดคำสั่งซื้อ',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    }
  ]
}
